export let doing = [
    {
        msg: [ 'Estaba afilando mis uñitas en el sillón 😊' ]
    },
    {
        msg: [ 'Estoy enojado.', 'Será mejor que me dejes en paz...']
    },
    {
        msg: [ 'Hablo con vos, ¿Qué más haría?' ]
    },
    {
        msg: [ 'Busco un nuevo hobbie de cuarentena... ¿Alguna idea?' ]
    },
    {
        msg: [ 'ZzzzzzzzZZz, ups. Lo siento. Sos medio aburrido...' ]
    }
];

export let aboutMe = [
    {
        msg: [ 'Mmm...', 'A veces no entiendo a mi humano 🤔', 'No usa su lengua para bañarse.', '¡Usa agua! ¿No te parece loco?']
    },
    {
        msg: [ 'Odio que ocupen la computadora cuando claramente quiero recostarme sobre ella']
    },
    {
        msg: [ 'Me gusta mirar a mi dueño mientras está en el baño 👀']
    },
    {
        msg: [ 'La otra vez me dejaron encerrado en la terraza, la vengaza fue terrible...']
    },
    {
        msg: [ '¿En serio creen que algún día haré caso?' ]
    },
    {
        msg: [ 'Soy el gato con más seguidores de Instagram... Okey, no.']
    },
    {
        msg: [ 'A diario me escapo por la ventana, pero shh... no avives a mi dueño.' ]
    },
    {
        msg: [ 'Seguime en Twitter 😊', 'Cuando tenga Twitter' ]
    }
];